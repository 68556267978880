import { mapData } from "@/utilities/DataMapping";

export interface IMiscProduct {
    id: number;
    name: string;
    cost: number;
}

export class MiscProduct implements IMiscProduct {
    
    constructor(data?: IMiscProduct) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IMiscProduct) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    name: string = "";
    cost: number = 0;

    /* billing info */
    quantity: number = 0;
    get totalCost(): number { return +this.cost * +this.quantity; }

}