


































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import { Ref, Watch } from "vue-property-decorator";
import { IAccount, Account } from "@/model/Account";
import { PaymentStage } from "@/model/Enums";
import { IMiscProduct, MiscProduct } from "@/model/MiscProduct";
import { Transaction } from "@/model/Transaction";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })
export default class Billing extends Vue {

    async mounted() {
        const getAccounts = async () => { await this.getAccounts(); };
        const getProducts = async () => { await this.getProducts(); };
        await Promise.all([
            getAccounts(),
            getProducts()
        ]);

        this.step = PaymentStage.ChooseAccounts;
    }

    //
    // -- properties
    //

    @Ref("accountsForm") readonly accountsForm!: VForm;


    step: PaymentStage = PaymentStage.None;

    accountHeaders = [
        { value: "description", text: "Account", sortable: false },
        { value: "balance", text: "Current Balance", sortable: false },
        { value: "amountToPay", text: "Amount To Pay", sortable: false }
    ];
    accounts: Array<Account> = [];
    selectedAccounts: Array<Account> = [];

    productHeaders = [
        { value: "name", text: "Product", sortable: false },
        { value: "cost", text: "Cost Pre Unit", sortable: false },
        { value: "quantity", text: "Quantity", sortable: false },
        { value: "totalCost", text: "Total", sortable: false, align: "end" }
    ];
    products: Array<MiscProduct> = [];

    transactionsHeaders = [
        { value: "description", text: "Item", sortable: false },
        { value: "costPerUnit", text: "Cost Per Unit", sortable: false },
        { value: "quantity", text: "Quantity", sortable: false, align: "center" },
        { value: "vat", text: "VAT", sortable: false, align: "end" },
        { value: "amount", text: "Cost", sortable: false, align: "end" }
    ]

    // 
    // -- computed properties
    //

    private get accountsTotal(): number {
        return this.selectedAccounts.reduce((total: number, account: Account) => total + (account.amountToPay == null ? 0 : +account.amountToPay), 0);
    }

    private get productsTotal(): number {
        return this.products.reduce((total: number, product: MiscProduct) => total + +product.totalCost, 0);
    }

    private get transactions(): Array<Transaction> {
        const transactions = this.selectedAccounts
            .map(account => {
                const transaction = new Transaction()
                transaction.accountID = account.id;
                transaction.description = account.description;
                transaction.costPerUnit = account.amountToPay == null ? 0 : +account.amountToPay;
                transaction.quantity = 1;
                return transaction
            });

        transactions.push(...this.products
            .filter(product => product.quantity > 0)
            .map(product => {
                const transaction = new Transaction();
                transaction.productID = product.id;
                transaction.description = product.name;
                transaction.costPerUnit = product.cost;
                transaction.quantity = product.quantity;
                return transaction;
            }));

        return transactions;
    }

    private get transactionsTotal(): number {
        return this.transactions.reduce((total: number, transaction: Transaction) => total + +transaction.amount, 0);
    }

    private get transactionsVat(): number {
        return this.transactions.reduce((total: number, transaction: Transaction) => total + +transaction.vat, 0);
    }

    private get transactionsSubTotal(): number {
        return this.transactions.reduce((total: number, transaction: Transaction) => total + +transaction.subTotal, 0);
    }

    //
    // -- watchers
    //

    @Watch("selectedAccounts")
    private onSelectedAccountsChanged() {
        this.accounts.forEach(account => {
            if (this.selectedAccounts.indexOf(account) < 0) 
                account.amountToPay = null;
            else if (this.selectedAccounts.indexOf(account) >= 0 && (account.amountToPay == null || account.amountToPay == 0) && account.balance < 0) 
                account.amountToPay = Math.abs(account.balance);
        });
    }

    //
    // -- methods
    //

    // fetch data

    private async getAccounts() {
        this.accounts = [];
        const serverData: Array<IAccount> = await apiClient.get("/api/occupancy/accounts");
        this.accounts.push(...serverData.map(a => new Account(a)));
    }

    private async getProducts() {
        this.products = [];
        const serverData: Array<IMiscProduct> = await apiClient.get("/api/billing/products");
        this.products.push(...serverData.map(p => new MiscProduct(p)));
    }

    // stages

    private moveToStage(stage: PaymentStage) {
        if (this.step == PaymentStage.ChooseAccounts && stage == PaymentStage.AdditionalProducts) {
            this.accountsForm.validate();
            const isValid = this.selectedAccounts
                .filter(account => account.amountToPay == null || +account.amountToPay <= 0)
                .length == 0;
            if (!isValid) {
                toastr.warning("Please fix highlighted issues", "Cannot Save")
                return;
            } 
        }

        this.step = stage;
    }

    private accountBlanceText(account: Account): string {
        if (account.balance > 0) return utils.toMoney(account.balance) + " in credit";
        if (account.balance < 0) return utils.toMoney(Math.abs(account.balance)) + " in arrears";
        return utils.toMoney(account.balance);
    }

    private accountSelected(account: Account): boolean {
        return this.selectedAccounts.indexOf(account) > -1;
    }

    private checkout() {
        // todo
    }

}
