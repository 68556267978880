export class Transaction {

    accountID: number = 0;
    productID: number = 0;
    description: string = "";
    costPerUnit: number = 0;
    quantity: number = 0;

    get amount(): number { return this.costPerUnit * this.quantity; }
    get vat(): number { return +((this.amount * 0.2).toFixed(2)); }
    get subTotal(): number { return this.amount - this.vat; }

}